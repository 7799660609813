import { useEffect } from 'react';

const NEEDED_UTM_KEYS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'utm_keyword',
];

function excludeSpaceSignFromUrl(str) {
  return str.replace(/%20/g, ' ');
}

const getTimestampsDifference = (timestamp1, timestamp2) => {
  const d1 = new Date(timestamp1);
  const d2 = new Date(timestamp2);

  const diff = d2 - d1;
  //minutes
  return Math.floor(diff / 60e3);
};

function getUrlSearchParams(search) {
  return search
    .slice(1)
    .split('&')
    .reduce((acc, s) => {
      const [k, v] = s.split('=');
      return Object.assign(acc, { [k]: v });
    }, {});
}

function checkUrlSearchParams(params, keys) {
  const utmParams = Object.entries(params).reduce((acc, el) => {
    const key = el[0];
    const value = el[1];

    if (!keys.includes(key)) {
      return acc;
    }

    return { ...acc, [key]: excludeSpaceSignFromUrl(value) };
  }, {});

  if (Object.keys(utmParams).length) {
    return utmParams;
  }
  return null;
}

const checkUTMTimestamp = () => {
  const UTMDataSession = sessionStorage.getItem('UTM_easytoolhire');
  const UTMData = UTMDataSession ? JSON.parse(UTMDataSession) : null;
  const sessionTimestamp = sessionStorage.getItem('UTM_timestamp_easytoolhire');

  //if we have utm timestamp we check if it's expired
  if (sessionTimestamp) {
    const currTimestamp = new Date().toISOString();
    const timestampsDifference = getTimestampsDifference(
      sessionTimestamp,
      currTimestamp,
    );

    if (timestampsDifference > 1440) {
      sessionStorage.setItem(
        'UTM_easytoolhire',
        JSON.stringify({
          utm_landing_page: UTMData?.utm_landing_page || window.location.href,
        }),
      );
      sessionStorage.setItem('UTM_timestamp_easytoolhire', '');
    }
  }
};

const fillUTMSession = () => {
  const utmStore = 'UTM_easytoolhire';

  const UTMDataSession = sessionStorage.getItem(utmStore);
  const UTMData = UTMDataSession ? JSON.parse(UTMDataSession) : null;
  const sessionTimestamp = sessionStorage.getItem('UTM_timestamp_easytoolhire');
  const currentLocation = window.location;
  const params = getUrlSearchParams(currentLocation.search);

  const utmFromUrl = checkUrlSearchParams(params, NEEDED_UTM_KEYS);
  const utmFromSession = checkUrlSearchParams(UTMData || {}, NEEDED_UTM_KEYS);

  //if no utm in url and no utm in session
  if (!utmFromUrl && !utmFromSession && !UTMData?.utm_landing_page) {
    sessionStorage.setItem(
      utmStore,
      JSON.stringify({
        utm_landing_page: currentLocation.href,
      }),
    );
    return;
  }

  //if we have utm in url or our old utm doesn't have timestamp set
  if (
    !!utmFromUrl ||
    (!sessionTimestamp &&
      utmFromSession &&
      Object.keys(utmFromSession).length > 1)
  ) {
    sessionStorage.setItem(
      utmStore,
      JSON.stringify({
        utm_landing_page: currentLocation.href,
        ...(utmFromUrl && { ...utmFromUrl }),
      }),
    );
    sessionStorage.setItem(
      'UTM_timestamp_easytoolhire',
      new Date().toISOString(),
    );
    return;
  }

  checkUTMTimestamp();
};

export const useSetUTM = () => {
  useEffect(() => {
    try {
      fillUTMSession();

      //clear session after 24 hours
      setInterval(function() {
        checkUTMTimestamp();
      }, 300000);
    } catch (e) {
      console.warn(e);
    }
  }, []);
};
