import { useEffect } from 'react';

export const useSetPageQueryString = () => {
  useEffect(() => {
    const pageQueryString = sessionStorage.getItem('landing_page_query_string');

    if (!pageQueryString && window.location.search) {
      sessionStorage.setItem(
        'landing_page_query_string',
        window.location.search,
      );
    }
  }, []);
};
