import React, { useMemo } from 'react';
import { regions } from 'consts';

import { HeaderNavDropdown } from '../HeaderNavDropdown';

export const Regions = () => {
  const regionsLinks = useMemo(
    () => regions.map(el => ({ url: el.url, title: el.name })),
    [],
  );

  return <HeaderNavDropdown linksList={regionsLinks} />;
};
