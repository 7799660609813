import React from 'react';
import HeroBanner from 'commonComponents/HeroBanner';
import { PoweredByBanner } from 'commonComponents/PoweredByBanner';
import Regions from 'commonComponents/Regions';

import { EnquiryLead } from 'features/EnquiryLead';

import AboutWilkoHire from './AboutWilkoHire';
import HireByCategory from './HireByCategory';
import HowItWorksSection from './HowItWorks';
import MapSection from './MapSection';
import Reviews from './Reviews';

import style from './main.module.scss';

export const Main = () => {
  return (
    <div>
      <PoweredByBanner />
      <HeroBanner />
      <Regions />
      <AboutWilkoHire />

      <div className={style.mainPageContentWrapper}>
        <HireByCategory />
      </div>

      <div className={style.mainPageContentWrapper}>
        <HowItWorksSection />
      </div>

      <MapSection />

      <div className={style.mainPageContentWrapper}>
        <Reviews />
      </div>

      <EnquiryLead source="Contact form (index page)" />
    </div>
  );
};
