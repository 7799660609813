import { createEvent, createStore } from 'effector';

export const setRegionModalState = createEvent();

export const regionModalState$ = createStore({ open: false }).on(
  setRegionModalState,
  (_, p) => p,
);

export const setIsMenuOpen = createEvent();
export const isMenuOpen$ = createStore(false).on(setIsMenuOpen, (_, p) => p);
