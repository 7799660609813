import React from 'react';
import clsx from 'clsx';

import {
  button,
  buttondark,
  buttoninline,
  buttoninverse,
  buttonLoading,
  buttonsmall,
  buttontransparent,
} from './button.module.scss';

export const Button = React.forwardRef(
  (
    {
      children,
      className,
      loading,
      disabled,
      inverse,
      transparent,
      dark,
      small,
      inline,
      prefixIcon: prefixIconComponent,
      postfixIcon: postfixIconComponent,
      prefix: prefixComponent,
      postfix: postfixComponent,
      as = 'button',
      ...rest
    },
    ref,
  ) => {
    return (
      <button
        disabled={disabled || loading}
        className={clsx(button, className, {
          [buttonLoading]: loading,
          [buttoninverse]: inverse,
          [buttontransparent]: transparent,
          [buttondark]: dark,
          [buttonsmall]: small,
          [buttoninline]: inline,
        })}
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    );
  },
);
