import React from 'react';
import Iubenda from 'react-iubenda-policy';
import clsx from 'clsx';
import { COMPANY_PHONE_NUMBER, COMPANY_PHONE_NUMBER_CALL } from 'consts';
import { useStore } from 'effector-react';
import { isMenuOpen$ } from 'models/common';

import AttachmentTop from './AttachementTop';

import { T8y } from 'ui';

import { ReactComponent as MessageIcon } from 'assets/images/icons/message.svg';
import { ReactComponent as CallIcon } from 'assets/images/icons/phoneWhite.svg';
import { ReactComponent as Whatsapp } from 'assets/images/icons/whatsapp.svg';

import style from './footer.module.scss';

const currentYear = new Date().getFullYear();

export const Footer = () => {
  const isMenuOpen = useStore(isMenuOpen$);

  return (
    <footer>
      <AttachmentTop />

      <div className={style.copyright}>
        <div className={style.footerRow}>
          <div className={clsx(style.phoneNumber, style.footerItem)}>
            <CallIcon width={19} />
            <T8y
              variant="t1v1"
              as="a"
              href={`tel:${COMPANY_PHONE_NUMBER_CALL}`}
              color="light"
              ml
            >
              {COMPANY_PHONE_NUMBER}
            </T8y>
          </div>
          <div className={clsx(style.footerEmail, style.footerItem)}>
            <MessageIcon width={19} />
            <T8y
              variant="t1v1"
              as="a"
              href="mailto:info@wilkotoolhire.com"
              color="light"
              ml
            >
              info@wilkotoolhire.com
            </T8y>
          </div>
          <T8y
            variant="t1v1"
            as="a"
            href="https://www.wilko.com"
            color="light"
            className={style.footerItem}
          >
            Back to wilko.com
          </T8y>
          <Iubenda id="37844183" type="privacy" styling="nostyle">
            Privacy Notice
          </Iubenda>
          <Iubenda id="37844183" type="cookie" styling="nostyle">
            Cookie Policy
          </Iubenda>
          <Iubenda id="37844183" type="terms-and-conditions" styling="nostyle">
            Terms & Conditions
          </Iubenda>
        </div>
        <T8y variant="t1v1" color="light" center>
          Copyright © {currentYear} easyToolhire. All rights reserved.
        </T8y>
      </div>
      {!isMenuOpen && (
        <T8y
          as="a"
          className={style.whatsappIcon}
          href={'https://wa.me/441182305303 '}
          target="_blank"
          aria-label="Whatsapp"
        >
          <Whatsapp />
        </T8y>
      )}
    </footer>
  );
};
