import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useStore } from 'effector-react';
import { regionModalState$ } from 'models/common';

import { SelectRegionModal } from 'features/SelectRegionModal';

import { Footer } from './Footer';
import { Header } from './Header';

export const Layout = ({ children }) => {
  const regionModalState = useStore(regionModalState$);

  const location = useLocation();

  useEffect(() => {
    if (window.scrollTo) {
      window.scrollTo({ top: 0 });
    }
  }, [location.pathname]);

  return (
    <div>
      <Header />
      <main>{children}</main>
      <Footer />
      {regionModalState.open && <SelectRegionModal />}
    </div>
  );
};
