import React from 'react';
import { LAPTOP_SCREEN, SMALL_SCREEN } from 'consts';
import { useMatchMedia } from 'hooks';
import { setRegionModalState } from 'models/common';

import MobileHeroBanner from './MobileHeroBanner';

import { Button, T8y } from 'ui';

import heroImage from 'assets/images/pictures/hero.png';

import style from './heroBanner.module.scss';

const HeroBanner = ({ category }) => {
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);
  const isLaptop = useMatchMedia(`(max-width: ${LAPTOP_SCREEN}px)`);

  return isSmallScreen ? (
    <MobileHeroBanner category={category} />
  ) : (
    <div className={style.bannerWrapper}>
      <div className={style.bannerContainer}>
        <div className={style.bannerContent}>
          <T8y as="h1" color="light" className={style.bannerContentTitle}>
            wilko tool hire – ready when you are
          </T8y>

          <T8y
            as="p"
            color="light"
            variant="t1"
            className={style.bannerContentText}
          >
            The easy and affordable way to hire exactly what you need for any
            home or garden project. Choose from a large range of tools and
            equipment to help you get jobs done, from quick fixes to full
            refurbs.
          </T8y>

          <Button
            onClick={() =>
              setRegionModalState({
                open: true,
                regions: category?.regions,
                slug: category?.slug,
              })
            }
            className={style.bannerContentBtn}
          >
            Select your region
          </Button>
        </div>
        {!isLaptop && (
          <div className={style.bannerImgWrapper}>
            <img src={heroImage} alt="Banner" className={style.heroImage} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroBanner;
