import React from 'react';
import { setRegionModalState } from 'models/common';

import { Button, T8y } from 'ui';

import style from './description.module.scss';

const Description = ({ category }) => {
  return (
    <div className={style.description}>
      <T8y variant="h1v3" xsVariant="t1" xsCenter bold mb2>
        The easy way to hire tools for your next home or garden project
      </T8y>
      <T8y as="p" variant="t1" xsVariant="t1v1">
        {category?.description}
      </T8y>

      <Button
        className={style.selectRegionBtn}
        onClick={() =>
          setRegionModalState({
            open: true,
            regions: category?.regions,
            slug: category?.slug,
          })
        }
      >
        Select your region
      </Button>
    </div>
  );
};

export default Description;
