import React, { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { validateEmailReg } from 'consts';

import { Button, Checkbox, PhoneField, T8y } from 'ui';

import style from './enquiryLead.module.scss';

export const EnquiryLead = ({ source }) => {
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isCheckBoxInfo, setIsCheckBoxInfo] = useState(false);

  const enquiryRef = useRef(null);

  const { register, handleSubmit, errors, reset, control } = useForm({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      company_name: '',
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const onSubmit = useCallback(
    async data => {
      setIsSendingRequest(true);

      const leadData = {
        'Full Name': data.name,
        'Phone Number': data.phone,
        'Company Name': data.company_name,
        'Email Address': data.email,

        'checkbox_consent to sharing my information with easyToolhire. We will never share your information with third parties without your permission.': isCheckBoxInfo,
      };

      const formData = {
        source,
        lead_page_url: window.location.href,
      };

      const UTMDataParsed = JSON.parse(
        sessionStorage.getItem('UTM_easytoolhire'),
      );

      const apiPrefix =
        window.location.origin.includes('stage.') ||
        window.location.origin.includes('localhost')
          ? 'stage-api'
          : 'api';

      try {
        const finalData = {
          ...data,
          ...formData,
          ...(UTMDataParsed && { utm: { ...UTMDataParsed } }),
          origin: 'wilkotoolhire.com',
        };

        await fetch(
          `https://${apiPrefix}.easytoolhire.com/api/v1/scotland/email/search`,
          {
            method: 'POST',
            body: JSON.stringify(finalData),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          },
        );

        const leadFinalData = {
          ...finalData,
          Territory: 'easytoolhire/UK other region',
          full_name: data.name,
          company_name: data.company_name || undefined,
          message: data.message || undefined,
          lead_type: 'enquiry',
          attributes: leadData,
          page_url: window.location.href,
          source,
        };

        await fetch(
          `https://${apiPrefix}.easytoolhire.com/api/v1/scotland/coldLead`,
          {
            method: 'POST',
            body: JSON.stringify(leadFinalData),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          },
        );

        setIsPost(true);

        toast.success('Your request has been sent successfully!');

        reset();
        setIsCheckBoxInfo(false);
      } catch (e) {
        console.warn(e);
        toast.error('Something went wrong');
      } finally {
        setIsSendingRequest(false);
      }
    },
    [isCheckBoxInfo, reset, source],
  );

  return (
    <div className={style.wrapper} id="enquiryForm">
      <div className={style.wrapperInner}>
        <T8y
          className={style.enquiryTitle}
          ref={enquiryRef}
          bold
          center
          fullWidth
        >
          Make an enquiry
        </T8y>

        <T8y variant="t1" xsVariant="t1v1" center mb3 mt2>
          Please feel free to contact us if you have any questions. We’ll be
          happy to help!
        </T8y>

        <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
          <div className={style.formContent}>
            <div className={style.short}>
              <input
                name="name"
                ref={register({
                  validate: value => !!value?.trim() || 'Required field*',
                })}
                placeholder="Full Name*"
                className={clsx(style.input, style.enquiryInput)}
              />

              <T8y variant="t4" color="danger" as="p" className={style.error}>
                {errors.name && errors.name.message}
              </T8y>
            </div>

            <div className={style.short}>
              <PhoneField
                className={clsx(style.input, style.enquiryPhoneInputWrapper)}
                placeholder="Phone Number*"
                control={control}
              />
              <T8y variant="t4" color="danger" as="p" className={style.error}>
                {errors.phone && errors.phone.message}
              </T8y>
            </div>
          </div>

          <div className={style.formContent}>
            <div className={style.short}>
              <input
                name="company_name"
                ref={register}
                placeholder="Company Name (optional)"
                className={clsx(style.input, style.enquiryInput)}
              />
              <T8y variant="t4" color="danger" as="p" className={style.error}>
                {errors.company_name && errors.company_name.message}
              </T8y>
            </div>
            <div className={style.short}>
              <input
                name="email"
                type="email"
                ref={register({
                  required: 'Required field*',
                  pattern: {
                    value: validateEmailReg,
                    message: 'Incorrect email',
                  },
                })}
                placeholder="Email Address*"
                className={clsx(style.input, style.enquiryInput)}
              />
              <T8y variant="t4" color="danger" as="p" className={style.error}>
                {errors.email && errors.email.message}
              </T8y>
            </div>
          </div>
          <textarea
            ref={register}
            name="message"
            rows="3"
            placeholder="Message"
            className={style.message}
          />

          <Checkbox
            type="button"
            checked={isCheckBoxInfo}
            onChange={() => setIsCheckBoxInfo(!isCheckBoxInfo)}
            label={
              <span>
                I consent to sharing my information with easyToolhire. We will
                never share your information with third parties without your
                permission.{' '}
                <Link to="/privacy-policy/" target="_blank">
                  See Privacy Notice here
                </Link>
              </span>
            }
          />

          <Button
            className={style.button}
            type="submit"
            disabled={!isCheckBoxInfo || isSendingRequest || isPost}
          >
            Submit enquiry
          </Button>
        </form>

        {isPost && (
          <div className={style.thankYouMsg}>
            <T8y color="success" center>
              Thank you for submitting your enquiry!
            </T8y>
            <T8y color="success" center>
              We’ll contact you shortly.
            </T8y>
          </div>
        )}
      </div>
    </div>
  );
};
