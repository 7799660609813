import React from 'react';
import clsx from 'clsx';

import style from './hamburger.module.scss';

const Hamburger = ({ open, onClick }) => {
  return (
    <div
      className={clsx(style.hamburger, open && style.open)}
      onClick={onClick}
    >
      <button type="button" aria-label="Open the menu">
        <span />
        <span />
        <span />
      </button>
    </div>
  );
};

export default Hamburger;
