import React from 'react';

import { T8y } from 'ui';

import style from './aboutWilkoHire.module.scss';

const AboutWilkoHire = () => {
  return (
    <div className={style.aboutWilkoHire}>
      <T8y variant="h1v3" xsVariant="t1" xsCenter bold mb2>
        The easy way to hire tools for your next home or garden project
      </T8y>
      <T8y as="p" variant="t1" xsVariant="t1v1">
        <T8y as="span" variant="t1" xsVariant="t1v1" bold>
          wilko tool hire
        </T8y>{' '}
        is here to help with an extensive range of tools and equipment available
        to hire for your home & garden jobs, powered by easyToolhire.
      </T8y>
      <br />
      <T8y as="p" variant="t1" xsVariant="t1v1">
        From small fixes to renovations and everything in between, you can hire
        the tools to help you get jobs done! Simply select what you need from
        the{' '}
        <T8y as="span" variant="t1" xsVariant="t1v1" bold>
          wilko tool hire
        </T8y>{' '}
        site and collect from an expanding network of depots across the UK – or
        you can have items delivered direct to your door.
      </T8y>
      <br />
      <T8y as="p" variant="t1" xsVariant="t1v1">
        Need a helping hand? Our friendly wilko tool hire customer care team is
        on hand to offer advice and guidance through the whole process. Get in
        touch today to get those jobs done with ease!
      </T8y>
    </div>
  );
};

export default AboutWilkoHire;
