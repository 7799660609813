import { useEffect, useRef, useState } from 'react';

export const useMatchMedia = query => {
  const [toggleChange, setToggleChange] = useState(false);
  const matchMediaRef = useRef(null);

  useEffect(() => {
    matchMediaRef.current = window.matchMedia(query);
    const initialMatch = matchMediaRef.current.matches;

    if (initialMatch) {
      setToggleChange(true);
    } else {
      setToggleChange(false);
    }

    const test = event => {
      if (event.matches) {
        setToggleChange(true);
      } else {
        setToggleChange(false);
      }
    };

    matchMediaRef.current.addListener(test);

    return () => {
      matchMediaRef.current.removeListener(test);
    };
  }, [query]);

  return toggleChange;
};
