import benefitIcon2 from 'assets/images/icons/benefits/wilko-benefit1.png';
import benefitIcon1 from 'assets/images/icons/benefits/wilko-benefit2.png';
import benefitIcon3 from 'assets/images/icons/benefits/wilko-benefit3.png';
import benefitIcon4 from 'assets/images/icons/benefits/wilko-benefit4.png';
import categoryImg_1 from 'assets/images/pictures/categories/category_1.png';
import categoryImg_2 from 'assets/images/pictures/categories/category_2.png';
import categoryImg_3 from 'assets/images/pictures/categories/category_3.png';
import categoryImg_4 from 'assets/images/pictures/categories/category_4.png';
import categoryImg_5 from 'assets/images/pictures/categories/category_5.png';
import categoryImg_6 from 'assets/images/pictures/categories/category_6.png';
import categoryImg_7 from 'assets/images/pictures/categories/category_7.png';
import categoryImg_8 from 'assets/images/pictures/categories/category_8.png';
import subCategoryImg_4_1 from 'assets/images/pictures/subCategories/cleaning/category_1.jpg';
import subCategoryImg_4_2 from 'assets/images/pictures/subCategories/cleaning/category_2.jpg';
import subCategoryImg_4_3 from 'assets/images/pictures/subCategories/cleaning/category_3.jpg';
import subCategoryImg_4_4 from 'assets/images/pictures/subCategories/cleaning/category_4.jpg';
import subCategoryImg_6_1 from 'assets/images/pictures/subCategories/climateControl/category_1.jpg';
import subCategoryImg_6_2 from 'assets/images/pictures/subCategories/climateControl/category_2.jpeg';
import subCategoryImg_6_3 from 'assets/images/pictures/subCategories/climateControl/category_3.jpg';
import subCategoryImg_6_4 from 'assets/images/pictures/subCategories/climateControl/category_4.png';
import subCategoryImg_7_1 from 'assets/images/pictures/subCategories/concreteAndEquipmentTools/category_1.png';
import subCategoryImg_7_2 from 'assets/images/pictures/subCategories/concreteAndEquipmentTools/category_2.jpg';
import subCategoryImg_7_3 from 'assets/images/pictures/subCategories/concreteAndEquipmentTools/category_3.png';
import subCategoryImg_7_4 from 'assets/images/pictures/subCategories/concreteAndEquipmentTools/category_4.jpg';
import subCategoryImg_3_1 from 'assets/images/pictures/subCategories/drillingAndBreakingTools/category_1.jpg';
import subCategoryImg_3_2 from 'assets/images/pictures/subCategories/drillingAndBreakingTools/category_2.png';
import subCategoryImg_3_3 from 'assets/images/pictures/subCategories/drillingAndBreakingTools/category_3.jpg';
import subCategoryImg_3_4 from 'assets/images/pictures/subCategories/drillingAndBreakingTools/category_4.png';
import subCategoryImg_1_1 from 'assets/images/pictures/subCategories/gardeningAndLandscaping/category_1.jpg';
import subCategoryImg_1_2 from 'assets/images/pictures/subCategories/gardeningAndLandscaping/category_2.png';
import subCategoryImg_1_3 from 'assets/images/pictures/subCategories/gardeningAndLandscaping/category_3.jpeg';
import subCategoryImg_1_4 from 'assets/images/pictures/subCategories/gardeningAndLandscaping/category_4.jpeg';
import subCategoryImg_5_1 from 'assets/images/pictures/subCategories/sanding/category_1.jpg';
import subCategoryImg_5_2 from 'assets/images/pictures/subCategories/sanding/category_2.jpg';
import subCategoryImg_5_3 from 'assets/images/pictures/subCategories/sanding/category_3.jpg';
import subCategoryImg_2_1 from 'assets/images/pictures/subCategories/sawsAndCuttingEquipment/category_1.jpg';
import subCategoryImg_2_2 from 'assets/images/pictures/subCategories/sawsAndCuttingEquipment/category_2.jpg';
import subCategoryImg_2_3 from 'assets/images/pictures/subCategories/sawsAndCuttingEquipment/category_3.jpg';
import subCategoryImg_2_4 from 'assets/images/pictures/subCategories/sawsAndCuttingEquipment/category_4.jpg';

export const validateEmailReg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const REACT_APP_GOOGLE_MAP_API_KEY =
  'AIzaSyD2cSgwEdHTP55SgkNhVvDWAPFPoscQiWs';

export const MOBILE_SCREEN = 575;
export const SMALL_SCREEN = 767;
export const LAPTOP_SCREEN = 991;
export const DESKTOP_SCREEN = 1200;

export const PUBLIC_URL = window.location.origin;

export const COMPANY_PHONE_NUMBER_CALL = '+441182305303';
export const COMPANY_PHONE_NUMBER = '+44 1182 305303';

export const regions = [
  {
    id: 1,
    name: 'East Midlands',
    state: '41',
    url: `${PUBLIC_URL}/east-midlands/`,
    default: null,
    isComingSoon: false,
  },
  {
    id: 2,
    name: 'East of England',
    state: '40',
    url: `${PUBLIC_URL}/east-of-england/`,
    default: null,
    isComingSoon: false,
  },
  {
    id: 3,
    name: 'England London',
    state: '35',
    url: `${PUBLIC_URL}/london/`,
    default: null,
    isComingSoon: false,
  },
  {
    id: 4,
    name: 'England North East',
    state: '36',
    url: `${PUBLIC_URL}/north-east-england/`,
    default: null,
    isComingSoon: false,
  },
  {
    id: 5,
    name: 'England North West',
    state: '37',
    url: `${PUBLIC_URL}/north-west-england/`,
    default: null,
    isComingSoon: false,
  },
  {
    id: 6,
    name: 'Scotland',
    state: '34',
    url: `${PUBLIC_URL}/scotland/`,
    default: null,
    isComingSoon: false,
  },
  {
    id: 7,
    name: 'South East England',
    state: '43',
    url: `${PUBLIC_URL}/south-east-england/`,
    default: null,
    isComingSoon: false,
  },
  {
    id: 8,
    name: 'South West England',
    state: '44',
    url: `${PUBLIC_URL}/south-west-england/`,
    default: null,
    isComingSoon: false,
  },
  {
    id: 9,
    name: 'Wales',
    state: '39',
    url: `${PUBLIC_URL}/wales/`,
    default: null,
    isComingSoon: false,
  },
  {
    id: 10,
    name: 'West Midlands',
    state: '42',
    url: `${PUBLIC_URL}/west-midlands/`,
    default: null,
    isComingSoon: false,
  },
  {
    id: 11,
    name: 'Yorkshire',
    state: '38',
    url: `${PUBLIC_URL}/yorkshire/`,
    default: null,
    isComingSoon: false,
  },
];

export const categories = [
  {
    id: 1,
    image: categoryImg_1,
    name: 'Gardening & Landscaping',
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/gardening-landscaping/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/gardening-landscaping/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/gardening-landscaping/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/gardening-landscaping/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/gardening-landscaping/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/gardening-landscaping/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/gardening-landscaping/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/gardening-landscaping/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/gardening-landscaping/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/gardening-landscaping/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/gardening-landscaping/`,
      },
    ],
  },
  {
    id: 2,
    image: categoryImg_2,
    name: 'Cleaning Tools',
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/cleaning/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/cleaning/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/cleaning/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/cleaning/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/cleaning/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/cleaning/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/cleaning/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/cleaning/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/cleaning/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/cleaning/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/cleaning/`,
      },
    ],
  },
  {
    id: 3,
    image: categoryImg_3,
    name: 'Sawing & Cutting',
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/saws-cutting-equipment/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/saws-cutting-equipment/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/saws-cutting-equipment/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/saws-cutting-equipment/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/saws-cutting-equipment/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/saws-cutting-equipment/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/saws-cutting-equipment/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/saws-cutting-equipment/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/saws-cutting-equipment/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/saws-cutting-equipment/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/saws-cutting-equipment/`,
      },
    ],
  },
  {
    id: 4,
    image: categoryImg_4,
    name: 'Painting & Decorating',
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/painting-decorating/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/painting-decorating/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/painting-decorating/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/painting-decorating/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/painting-decorating/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/painting-decorating/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/painting-decorating/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/painting-decorating/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/painting-decorating/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/painting-decorating/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/painting-decorating/`,
      },
    ],
  },
  {
    id: 5,
    image: categoryImg_5,
    name: 'Drilling & Breaking Tools',
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/drilling-and-breaking-tools/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/drilling-and-breaking-tools/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/drilling-and-breaking-tools/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/drilling-and-breaking-tools/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/drilling-and-breaking-tools/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/drilling-and-breaking-tools/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/drilling-and-breaking-tools/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/drilling-and-breaking-tools/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/drilling-and-breaking-tools/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/drilling-and-breaking-tools/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/drilling-and-breaking-tools/`,
      },
    ],
  },
  {
    id: 6,
    image: categoryImg_6,
    name: 'Sanding',
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/sanding/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/sanding/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/sanding/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/sanding/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/sanding/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/sanding/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/sanding/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/sanding/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/sanding/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/sanding/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/sanding/`,
      },
    ],
  },
  {
    id: 7,
    image: categoryImg_7,
    name: 'Concrete Equipment & Tools',
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/concrete-equipment-tools/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/concrete-equipment-tools/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/concrete-equipment-tools/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/concrete-equipment-tools/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/concrete-equipment-tools/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/concrete-equipment-tools/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/concrete-equipment-tools/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/concrete-equipment-tools/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/concrete-equipment-tools/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/concrete-equipment-tools/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/concrete-equipment-tools/`,
      },
    ],
  },
  {
    id: 8,
    image: categoryImg_8,
    name: 'Pipework & Plumbing',
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/pipework-plumbing/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/pipework-plumbing/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/pipework-plumbing/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/pipework-plumbing/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/pipework-plumbing/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/pipework-plumbing/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/pipework-plumbing/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/pipework-plumbing/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/pipework-plumbing/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/pipework-plumbing/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/pipework-plumbing/`,
      },
    ],
  },
];

export const reviews = [
  {
    rating: 5,
    author: 'John McKinnon, Edinburgh',
    review:
      'I used easyToolhire for the first time last week. They were recommended to me by a friend. I wasn’t disappointed. I was unsure of which machine I needed for a gardening project I have been working on. The team asked me a few questions and recommended the best machine for the job. The team was really friendly and the machine did the job.',
  },
  {
    rating: 5,
    author: 'David Brotchie, Glasgow',
    review:
      'I needed a mini digger for a last minute construction job. easyToolhire had just what I needed. They saved me a job and delivered the mini digger to the site, and collected it too. I wouldn’t hesitate in using easyToolhire again and would definitely recommend them.',
  },
  {
    rating: 5,
    author: 'Steve Hicks, Perth',
    review:
      'I needed to hire a concrete poker, mixer and plate compactor for a project I was working on. The equipment was in great condition and the team at the depot were really helpful. I found the hiring process to be really simple and straightforward. I wouldn’t hesitate in using them again. Thanks.',
  },
];

export const warehouses = [
  {
    name: 'easyToolHire - Edinburgh Depot',
    address: '13/1 South Gyle Crescent Edinburgh EH12 9EB',
    googleMapLink:
      'https://www.google.it/maps/place/easyToolhire+Edinburgh+-+Digger+Hire,+Scaffolding,+Scissor+Lift,+Compressors,+Welding+Machines,+Work+Platforms+%26+Garden+Shredder,+Tool+Hire/@55.9292124,-3.2989041,17z/data=!3m1!4b1!4m5!3m4!1s0x4887c5d03ec9c067:0xebb52b3d4e897cf!8m2!3d55.9292124!4d-3.2967154',
    lat: 55.92979,
    lng: -3.30185,
  },
  {
    name: 'easyToolHire - Motherwell',
    address: '95 Orbiston Street Motherwell ML1 1PX',
    googleMapLink:
      'https://www.google.it/maps/place/easyToolhire+-+Tool+Hire+Motherwell,+Scaffolding,+Scissor+Lift,+Air+Compressors,+Welder+Generator,+Work+Platform,+Wood+Chipper+%26+Any+Other+Tool+Hire+Near+You/@55.7858259,-3.984062,17z/data=!3m1!4b1!4m5!3m4!1s0x48886b5d1d4206b1:0xa8d0c83d2100ce1f!8m2!3d55.7858259!4d-3.9818733',
    lat: 55.78582,
    lng: -3.98187,
  },
  {
    name: 'easyToolHire - Govan Depot',
    address: '25 Robert Street Glasgow G51 3HB',
    googleMapLink:
      'https://www.google.com/maps/place//data=!4m2!3m1!1s0x4888459debd12a43:0x72713bcda6cc002f?source=g.page.share',
    lat: 55.86152,
    lng: -4.31129,
  },
  {
    name: 'easyToolHire - Ayr Depot',
    address: '30 Boundary Road Heathfield Industrial Estate Ayr KA8 9DJ',
    googleMapLink:
      'https://www.google.it/maps/place/easyToolhire+-+Tool+Hire+Ayr,+Excavators,+Scaffolding,+Drills,+Compressors,+Welding+Machines,+Sweepers+Near+You/@55.4851242,-4.6045836,15z/data=!4m12!1m6!3m5!1s0x4889d69f9358c7ef:0x8aaf3fa667e15e14!2sMartin+Plant+Hire!8m2!3d55.4825019!4d-4.6022657!3m4!1s0x0:0xe74788986c5026fe!8m2!3d55.4810848!4d-4.602904',
    lat: 55.48108,
    lng: -4.6029,
  },
  {
    name: 'easyToolHire - Kirkcaldy Depot',
    address:
      'Unit 8 Carberry Place Mitchelson Industrial Estate Kirkcaldy Fife KY1 3NQ',
    googleMapLink:
      'https://www.google.com/maps/place//data=!4m2!3m1!1s0x4887b54c0b509991:0x73104ec5fea52e4e?source=g.page.share',
    lat: 56.13607,
    lng: -3.14543,
  },
  {
    name: 'easyToolHire - Falkirk Depot',
    address: 'Unit 8, Etna Court Middlefield Industrial Estate Falkirk FK2 9EQ',
    googleMapLink: 'https://maps.app.goo.gl/mSmUSuLY9akEYEHp9?g_st=ic',
    lat: 56.01088,
    lng: -3.76585,
  },
  {
    name: 'easyToolHire - Perth Depot',
    address: '97 Crieff Road Perth PH1 2QB',
    googleMapLink: 'https://maps.app.goo.gl/sTg5CTdqdNd3GW2B7?g_st=ic',
    lat: 56.40673,
    lng: -3.46355,
  },
  {
    name: 'easyToolhire - London depot',
    address: 'B7-B9 Suttons Business Park, New Road, Rainham, Essex RM13 8DE',
    googleMapLink: 'https://goo.gl/maps/JBM4fHY4yXKmC4Uh8',
    lat: 51.523705,
    lng: 0.175797,
  },
  {
    name: 'easyToolhire - Newcastle',
    address:
      '337-343 Shields Rd Newcastle upon Tyne Tyne and Wear, United Kingdom, NE6 2UD',
    googleMapLink: 'https://goo.gl/maps/aBuYHhRsNSu21XUE9',
    lat: 54.980083,
    lng: -1.575664,
  },
  {
    name: 'easyToolhire - Alnwick',
    address: 'Willowtree Industrial Estate, Alnwick, United Kingdom,  NE66 2HA',
    googleMapLink: 'https://goo.gl/maps/iz5HSbkJ4c4AuDms8',
    lat: 55.401452,
    lng: -1.690772,
  },
  {
    name: 'easyToolhire - Blyth',
    address: 'Cowley Rd, Blyth, United Kingdom, NE24 5TF',
    googleMapLink: 'https://goo.gl/maps/EJdo6mxWHPrN9eG47',
    lat: 55.133749,
    lng: -1.534359,
  },
  {
    name: 'easyToolhire - Middlesbrough',
    address: 'Unit 7 Cannon Park, Middlesbrough, United Kingdom, TS1 5JP',
    googleMapLink: 'https://goo.gl/maps/3FEFWKFKARC2Phop8',
    lat: 54.575217,
    lng: -1.247861,
  },
  {
    name: 'easyToolhire - Sunderland',
    address:
      'Unit 26 Pallion Industrial Estate, Luxemburg Rd, Sunderland, SR4 6SJ',
    googleMapLink: 'https://goo.gl/maps/uzGhsX6jYwjgSTq39',
    lat: 54.917766,
    lng: -1.407409,
  },
  {
    name: 'easyToolhire - South Shields',
    address: 'Maxwell St, South Shields, United Kingdom, NE33 4PU',
    googleMapLink: 'https://goo.gl/maps/WikSaMUGcFvZR5TQ8',
    lat: 54.99279,
    lng: -1.4335,
  },
  {
    name: 'easyToolhire - Blaydon depot',
    address:
      'Unit 8 Blaydon Trade Park/Toll Bridge Rd, Blaydon-on-Tyne, United Kingdom, NE21 5TR',
    googleMapLink: 'https://goo.gl/maps/xdAYkZAiJMnjSSGF7',
    lat: 54.965041,
    lng: -1.696344,
  },
  {
    name: 'easyToolhire - Bolton',
    address: '135 Bridgeman Street, Bolton, United Kingdom, BL3 6BS',
    googleMapLink: 'https://goo.gl/maps/TmX6ZwFjyqe2ETtEA',
    lat: 53.571148,
    lng: -2.429604,
  },
  {
    name: 'easyToolhire - Preston',
    address:
      'Unit 427, Oakshott Place, Walton Summit Ind Est, Preston, United Kingdom, PR5 8AT',
    googleMapLink: 'https://goo.gl/maps/KaKEG2Fg2kbQE2NC6',
    lat: 53.72186,
    lng: -2.636702,
  },
  {
    name: 'easyToolhire - Wirral',
    address:
      'Unit 6, Croft Business Park, Bromborough, Wirral, United Kingdom, CH62 3NY',
    googleMapLink: 'https://goo.gl/maps/RvdY5SYTzKFqcpJF6',
    lat: 53.334194,
    lng: -2.969777,
  },
  {
    name: 'easyToolhire - Liverpool',
    address: '166 Great Howard St, Liverpool, United Kingdom, L3 7DW',
    googleMapLink: 'https://goo.gl/maps/GAf8D4BwQ1qtBhFBA',
    lat: 53.42036,
    lng: -2.996327,
  },
  {
    name: 'easyToolhire - Wigan',
    address: 'Fourmarts Rd, Martland Park, Wigan, WN5 0LR',
    googleMapLink: 'https://goo.gl/maps/gRwJedoeZQTKpkjA8',
    lat: 53.554023,
    lng: -2.673691,
  },
  {
    name: 'easyToolhire - Manchester Central',
    address: 'Ashton Old Road, Openshaw, Manchester, United Kingdom, M11 2DW',
    googleMapLink: 'https://goo.gl/maps/DMBUcQHrXnTnHH9e9',
    lat: 53.553942,
    lng: -2.673713,
  },
  {
    name: 'easyToolhire - Blackpool',
    address: '31 Mowbray Drive, Blackpool, United Kingdom, FY3 7UN',
    googleMapLink: 'https://goo.gl/maps/VNUzbrPSm9cSPF1t7',
    lat: 53.835962,
    lng: -3.025839,
  },
  {
    name: 'easyToolhire - Cumbria',
    address:
      'Unit 4A Crakeside Business Park, Greenodd, United Kingdom, LA12 7RT',
    googleMapLink: 'https://goo.gl/maps/qZnU8HP1pKUHGtz99',
    lat: 54.235243,
    lng: -3.053437,
  },
  {
    name: 'easyToolhire - Trafford Park',
    address:
      'Manchester Trafford Park, Unit 29, Longwood Road, Manchester, United Kingdom, M17 1PZ',
    googleMapLink: 'https://goo.gl/maps/gJLcVNpHBjaDMsim8',
    lat: 54.235148,
    lng: -3.053453,
  },
  {
    name: 'easyToolhire - Wythenshawe',
    address:
      '2 Ledson Rd, Roundthorn Ind Est, Wythenshawe, United Kingdom, M23 9GP',
    googleMapLink: 'https://goo.gl/maps/hFM2UR1o7AVvKEMi9',
    lat: 53.393031,
    lng: -2.291229,
  },
  {
    name: 'easyToolhire - Blackburn',
    address:
      'Unit 4, Lodge Court, Alan Ramsbottom Way, Great Harwood, United Kingdom, BB6 7FB',
    googleMapLink: 'https://goo.gl/maps/bPzUHBb1YJgk7JRb9',
    lat: 53.783731,
    lng: -2.397849,
  },
  {
    name: 'easyToolhire - Chorley',
    address:
      'Unit 9, Chorley West Business Park, Ackhurst Rd, Chorley, PR7 1NL',
    googleMapLink: 'https://goo.gl/maps/UAxLXYaSCTvMQwQN9',
    lat: 53.656356,
    lng: -2.655613,
  },
  {
    name: 'easyToolhire - Newton le willows',
    address:
      'Junction Lane, Sankey Valley Ind Est, Newton le willows, United Kingdom, WA12 8DN',
    googleMapLink: 'https://goo.gl/maps/HMtB2Njyc3zcM2Vq8',
    lat: 53.447942,
    lng: -2.644084,
  },
  {
    name: 'easyToolhire - Yorkshire',
    address: 'Unit 2-3, Victoria Park, Lightowler Rd, Halifax HX1 5ND',
    googleMapLink: 'https://goo.gl/maps/97Dp72pc5QMJwbfF7',
    lat: 53.724111,
    lng: -1.876777,
  },
];

const subCategories = {
  'gardening-landscaping': [
    { image: subCategoryImg_1_1, name: 'Garden Clearance' },
    { image: subCategoryImg_1_2, name: 'Lawn care' },
    { image: subCategoryImg_1_3, name: 'Mowing' },
    { image: subCategoryImg_1_4, name: 'Sawing / Trimming' },
  ],
  'saws-cutting-equipment': [
    { image: subCategoryImg_2_1, name: 'Concrete/Masonry Saws' },
    { image: subCategoryImg_2_2, name: 'Metal Grinding' },
    { image: subCategoryImg_2_3, name: 'Tile Cutting' },
    { image: subCategoryImg_2_4, name: 'Woodworking and Joinery' },
  ],
  'drilling-and-breaking-tools': [
    { image: subCategoryImg_3_1, name: 'Combi-Hammers' },
    { image: subCategoryImg_3_2, name: 'Core Drill Accessories' },
    { image: subCategoryImg_3_3, name: 'Drills' },
    { image: subCategoryImg_3_4, name: 'Electric Breakers' },
  ],
  cleaning: [
    { image: subCategoryImg_4_1, name: 'Carpet cleaners' },
    { image: subCategoryImg_4_2, name: 'Floor Scrubbers' },
    { image: subCategoryImg_4_3, name: 'Pressure washers' },
    { image: subCategoryImg_4_4, name: 'Vacuum cleaners' },
  ],
  sanding: [
    { image: subCategoryImg_5_1, name: 'Floor Sanding Equipment' },
    { image: subCategoryImg_5_2, name: 'Orbital and Belt Sanders' },
    { image: subCategoryImg_5_3, name: 'Wall Sanders' },
  ],
  'climate-control': [
    { image: subCategoryImg_6_1, name: 'Air Conditioners' },
    { image: subCategoryImg_6_2, name: 'Drying equipment' },
    { image: subCategoryImg_6_3, name: 'Electric Heaters' },
    { image: subCategoryImg_6_4, name: 'Radiant Heaters' },
  ],
  'concrete-equipment-tools': [
    { image: subCategoryImg_7_1, name: 'Concrete Finishing' },
    { image: subCategoryImg_7_2, name: 'Floor renovation' },
    { image: subCategoryImg_7_3, name: 'Mixing / Pouring' },
    { image: subCategoryImg_7_4, name: 'Surface Preparation' },
  ],
};

export const nationalCategories = [
  {
    id: 1,
    name: 'Gardening & Landscaping',
    slug: 'gardening-landscaping',
    description:
      "Transform your outdoor spaces with ease using our Gardening & Landscaping tools for hire. Whether you're laying new turf, trimming hedges, or creating flower beds, wilko tool hire offers the perfect selection of equipment for every gardening task. Our range includes powerful lawn mowers, rotavators, Chippers/Shredders, Stump Grinders, hedge trimmers and much more, ensuring your garden project is a breeze. Experience the convenience of wilko tool hire with our special promo for Wilko customers, bringing you affordable solutions to make your garden dreams a reality. Hire from us for an easy, cost-effective way to beautify your outdoor area.",
    subCategories: subCategories['gardening-landscaping'],
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/gardening-landscaping/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/gardening-landscaping/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/gardening-landscaping/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/gardening-landscaping/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/gardening-landscaping/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/gardening-landscaping/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/gardening-landscaping/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/gardening-landscaping/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/gardening-landscaping/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/gardening-landscaping/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/gardening-landscaping/`,
      },
    ],
  },
  {
    id: 2,
    name: 'Cutting & Sawing',
    slug: 'saws-cutting-equipment',
    description:
      "Tackle any cutting job with precision and ease, thanks to wilko tool hire's comprehensive range of Cutting & Sawing equipment. From intricate woodworking projects to major renovations, find the perfect tools including circular saws, jigsaws, and tile cutters. Our equipment is designed to provide clean, accurate cuts for materials of all types. With our special Wilko customer promo, accessing top-quality sawing and cutting tools has never been more affordable. Choose wilko tool hire for reliable tools that make your cutting tasks straightforward and efficient.",
    subCategories: subCategories['saws-cutting-equipment'],
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/saws-cutting-equipment/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/saws-cutting-equipment/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/saws-cutting-equipment/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/saws-cutting-equipment/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/saws-cutting-equipment/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/saws-cutting-equipment/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/saws-cutting-equipment/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/saws-cutting-equipment/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/saws-cutting-equipment/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/saws-cutting-equipment/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/saws-cutting-equipment/`,
      },
    ],
  },
  {
    id: 3,
    name: 'Drilling & Breaking',
    slug: 'drilling-and-breaking-tools',
    description:
      "For all your drilling and breaking needs, wilko tool hire has you covered. Our selection of drills, breakers, and needle guns are ideal for both light home renovations and heavy-duty construction work. Whether you're mounting shelves, breaking concrete, or tackling masonry jobs, our equipment ensures optimal performance. Benefit from our special promo for Wilko customers, making it easier and more affordable to rent the high-quality tools you need. Trust wilko tool hire to provide powerful, reliable solutions for drilling and breaking tasks of any size.",
    subCategories: subCategories['drilling-and-breaking-tools'],
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/drilling-and-breaking-tools/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/drilling-and-breaking-tools/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/drilling-and-breaking-tools/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/drilling-and-breaking-tools/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/drilling-and-breaking-tools/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/drilling-and-breaking-tools/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/drilling-and-breaking-tools/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/drilling-and-breaking-tools/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/drilling-and-breaking-tools/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/drilling-and-breaking-tools/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/drilling-and-breaking-tools/`,
      },
    ],
  },
  {
    id: 4,
    name: 'Cleaning',
    slug: 'cleaning',
    description:
      'Keep your spaces spotless with our range of Cleaning equipment available for hire. From deep carpet cleaning to pressure washing driveways, wilko tool hire offers a variety of tools to tackle dirt and grime in any setting. Our selection includes powerful pressure washers, carpet cleaners, and floor scrubbers, ensuring a thorough clean every time. Take advantage of our special Wilko customer promo for cost-effective cleaning solutions. With wilko tool hire, achieving professional-level cleanliness is simple and affordable.',
    subCategories: subCategories.cleaning,
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/cleaning/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/cleaning/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/cleaning/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/cleaning/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/cleaning/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/cleaning/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/cleaning/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/cleaning/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/cleaning/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/cleaning/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/cleaning/`,
      },
    ],
  },
  {
    id: 5,
    name: 'Sanding',
    slug: 'sanding',
    description:
      "Achieve smooth finishes on wood, walls, and floors with wilko tool hire's Sanding equipment. Our range, ideal for refurbishing furniture or preparing surfaces for painting, includes belt sanders, orbital sanders, and floor sanders. Perfect for both DIY enthusiasts and professional tradespeople, our sanding tools make quick work of any project. Enjoy special discounts for Wilko customers and rent with ease, knowing you have the right tools to create flawless finishes.",
    subCategories: subCategories.sanding,
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/sanding/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/sanding/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/sanding/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/sanding/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/sanding/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/sanding/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/sanding/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/sanding/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/sanding/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/sanding/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/sanding/`,
      },
    ],
  },
  {
    id: 6,
    name: 'Climate Control',
    slug: 'climate-control',
    description:
      "Maintain comfortable environments year-round with our Climate Control solutions. Wilko tool hire provides a selection of heaters, coolers, and dehumidifiers, suitable for any indoor space, from homes to offices. Whether you're combating summer heat, winter cold, or damp conditions, our equipment ensures optimal comfort. Take advantage of our special promo for Wilko customers and experience the benefits of efficient, affordable climate control rentals.",
    subCategories: subCategories['climate-control'],
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/climate-control/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/climate-control/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/climate-control/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/climate-control/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/climate-control/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/climate-control/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/climate-control/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/climate-control/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/climate-control/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/climate-control/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/climate-control/`,
      },
    ],
  },
  {
    id: 7,
    name: 'Concreting',
    slug: 'concrete-equipment-tools',
    description:
      "From laying new driveways to setting foundations, wilko tool hire's Concreting equipment supports all your construction needs. Offering concrete mixers, power floats, and vibrating pokers, we provide everything required for mixing, pouring, and finishing concrete with precision. Utilise our special Wilko customer promo for an economical way to access high-quality concreting tools. Rely on wilko tool hire for durable, efficient solutions that ensure professional results for any concreting project.",
    subCategories: subCategories['concrete-equipment-tools'],
    regions: [
      {
        name: 'East Midlands',
        url: `${PUBLIC_URL}/east-midlands/concrete-equipment-tools/`,
      },
      {
        name: 'East of England',
        url: `${PUBLIC_URL}/east-of-england/concrete-equipment-tools/`,
      },
      {
        name: 'England London',
        url: `${PUBLIC_URL}/london/concrete-equipment-tools/`,
      },
      {
        name: 'England North East',
        url: `${PUBLIC_URL}/north-east-england/concrete-equipment-tools/`,
      },
      {
        name: 'England North West',
        url: `${PUBLIC_URL}/north-west-england/concrete-equipment-tools/`,
      },
      {
        name: 'Scotland',
        url: `${PUBLIC_URL}/scotland/concrete-equipment-tools/`,
      },
      {
        name: 'South East England',
        url: `${PUBLIC_URL}/south-east-england/concrete-equipment-tools/`,
      },
      {
        name: 'South West England',
        url: `${PUBLIC_URL}/south-west-england/concrete-equipment-tools/`,
      },
      {
        name: 'Wales',
        url: `${PUBLIC_URL}/wales/concrete-equipment-tools/`,
      },
      {
        name: 'West Midlands',
        url: `${PUBLIC_URL}/west-midlands/concrete-equipment-tools/`,
      },
      {
        name: 'Yorkshire',
        url: `${PUBLIC_URL}/yorkshire/concrete-equipment-tools/`,
      },
    ],
  },
];

export const howItWorks = [
  {
    title: 'Select Your Region',
    text:
      'Choose your region to access a tailored selection of tools perfect for your local needs.',
    icon: benefitIcon1,
  },
  {
    title: 'Pick Your Tools',
    text:
      'Browse and select from our extensive range to suit your project needs.',
    icon: benefitIcon2,
  },
  {
    title: 'Book',
    text: 'Secure your tools with our simple, transparent booking process.',
    icon: benefitIcon3,
  },
  {
    title: 'Get It Delivered and Collected',
    text:
      'Enjoy the convenience of having your tools delivered and collected at your preferred time.',
    icon: benefitIcon4,
  },
];
