import React, { useLayoutEffect } from 'react';

import { BreadCrumbs } from './BreadCrumbs';

import style from './cookiePolicy.module.scss';

export const CookiePolicy = () => {
  useLayoutEffect(() => {
    const loader = function() {
      const s = document.createElement('script'),
        tag = document.getElementsByTagName('script')[0];
      s.src = 'https://cdn.iubenda.com/iubenda.js';
      tag.parentNode.insertBefore(s, tag);
    };
    loader();
  }, []);

  return (
    <div className={style.cookiePolicy}>
      <BreadCrumbs />
      <a
        href="https://www.iubenda.com/privacy-policy/37844183/cookie-policy"
        className="iubenda-white no-brand iubenda-embed iub-body-embed"
        title="Cookie Policy"
        style={{ display: 'none' }}
      >
        Cookie Policy
      </a>
    </div>
  );
};
