import React from 'react';
import { REACT_APP_GOOGLE_MAP_API_KEY } from 'consts';

import { useLoadScript } from '@react-google-maps/api';

import { Spinner } from 'ui';

const PLACES = ['places'];

const GoogleMapsWrapper = ({ children }) => {
  const { isLoaded } = useLoadScript({
    id: 'script-loader',
    googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: PLACES,
  });

  return isLoaded ? children : <Spinner size={4} />;
};

export default GoogleMapsWrapper;
