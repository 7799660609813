import React from 'react';
import { regions } from 'consts';

import { T8y } from 'ui';

import style from './regions.module.scss';

const Regions = ({ categorySlug }) => {
  return (
    <>
      <div className={style.regions}>
        <T8y variant="t1" bold className={style.regionsTitle}>
          Select your region
        </T8y>
        <div className={style.regionsContainer}>
          {regions.map((el, index) => (
            <a
              key={index}
              href={categorySlug ? `${el.url}${categorySlug}/` : el.url}
              className={style.regionLink}
            >
              <T8y variant="t1v1" xsVariant="t2v1" bold>
                {el.name}
              </T8y>
            </a>
          ))}
        </div>
      </div>
      {!categorySlug && <hr className={style.hLine} />}
    </>
  );
};

export default Regions;
