import React from 'react';
import { setRegionModalState } from 'models/common';

import { Button, T8y } from 'ui';

import heroImage from 'assets/images/pictures/hero.png';

import style from './mobileHeroBanner.module.scss';

const MobileHeroBanner = ({ category }) => {
  return (
    <div className={style.bannerWrapper}>
      <div className={style.bannerContainer}>
        <div className={style.imageWrapper}>
          <img src={heroImage} className={style.bannerImage} />
        </div>

        <div className={style.bannerContent}>
          <T8y
            as="h1"
            color="light"
            center
            bold
            className={style.bannerContentTitle}
          >
            wilko tool hire – ready when you are
          </T8y>

          <Button
            onClick={() =>
              setRegionModalState({
                open: true,
                regions: category?.regions,
                slug: category?.slug,
              })
            }
            className={style.bannerContentBtn}
          >
            Select your region
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileHeroBanner;
