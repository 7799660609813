import React from 'react';
import clsx from 'clsx';

import { T8y } from 'ui';

import { ReactComponent as Arrow } from 'assets/images/icons/whiteArrow.svg';

import style from './menuItem.module.scss';

export const MenuItem = ({
  title,
  href,
  isSubMenuOpen,
  handleSetIsSubMenuOpen,
  isSingleLink,
  children,
}) => {
  return (
    <div
      className={clsx(
        isSubMenuOpen && style.linkHover,
        isSingleLink ? style.single : style.linkWrapper,
        !isSingleLink && style.disclosure,
      )}
      onMouseOver={() => handleSetIsSubMenuOpen(true)}
      onMouseLeave={() => handleSetIsSubMenuOpen(false)}
      onClick={() => handleSetIsSubMenuOpen(!isSubMenuOpen)}
    >
      <>
        {href ? (
          <T8y
            variant="t1v1"
            as="a"
            href={href}
            color="light"
            className={style.link}
            noWrap
          >
            {title}
          </T8y>
        ) : (
          <T8y
            variant="t1v1"
            as="span"
            color="light"
            className={style.link}
            noWrap
          >
            {title}
          </T8y>
        )}
        {!isSingleLink && <Arrow className={style.dropdownArrow} />}
      </>
      {children}
    </div>
  );
};
