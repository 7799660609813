import React, { useCallback, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import { COMPANY_PHONE_NUMBER, COMPANY_PHONE_NUMBER_CALL } from 'consts';
import { useStore } from 'effector-react';
import { isMenuOpen$, setIsMenuOpen } from 'models/common';

import Hamburger from './Hamburger';
import SubMenuCategories from './SubMenuCategories';
import SubMenuRegions from './SubMenuRegions';

import { T8y } from 'ui';

import style from './mobileHeader.module.scss';

const MobileNav = () => {
  const isMenuOpen = useStore(isMenuOpen$);

  const [openId, setOpenId] = useState(null);

  const toggleMenu = useCallback(e => {
    if (!e.target) {
      return;
    }

    if (e.target.classList.contains('disable-scrolling')) {
      setIsMenuOpen(false);
    }
  }, []);

  useLayoutEffect(
    () => {
      if (isMenuOpen) {
        if (window.innerWidth && document.body.clientWidth) {
          document.body.style.paddingRight =
            document.body.scrollHeight !== window.innerHeight
              ? `${window.innerWidth - document.body.clientWidth}px`
              : '0';
        }
        document.querySelector('body').classList.add('disable-scrolling');
        document.addEventListener('click', toggleMenu);

        return () => {
          document.addEventListener('click', toggleMenu);
          document.body.style.removeProperty('padding-right');
          document.querySelector('body').classList.remove('disable-scrolling');
          setOpenId(null);
        };
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMenuOpen],
  );

  return (
    <>
      <Hamburger open={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)} />
      <div className={clsx(style.mobileMenu, isMenuOpen && style.open)}>
        <div className={style.linksWrapper}>
          <SubMenuRegions id={1} openId={openId} setOpenId={setOpenId} />
          <SubMenuCategories id={2} openId={openId} setOpenId={setOpenId} />
          <T8y
            as="a"
            href="https://www.wilko.com"
            variant="t1"
            className={clsx(style.subMenuTitle, style.backToWilkoLink)}
          >
            Back to wilko.com
          </T8y>
          <a
            href={`tel:${COMPANY_PHONE_NUMBER_CALL}`}
            className={clsx(style.subMenuTitle, style.subMenuPhone)}
          >
            <T8y variant="t1">Call Free</T8y>
            <T8y variant="t1">{COMPANY_PHONE_NUMBER}</T8y>
          </a>
        </div>
      </div>
    </>
  );
};

export default MobileNav;
