import React from 'react';

import style from './checkbox.module.scss';

export const Checkbox = ({ name, id = 'checkbox', label, onChange }) => {
  return (
    <div className={style.checkbox}>
      <input name={name} type="checkbox" id={id} onChange={onChange} />
      {label && (
        <label htmlFor={id} className={style.checkboxLabel}>
          {label}
        </label>
      )}
    </div>
  );
};
