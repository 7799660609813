import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
import Route from 'react-router-dom/es/Route';
import Switch from 'react-router-dom/es/Switch';
import { useSetPageQueryString, useSetUTM } from 'hooks';
import { Layout } from 'layout';
import { CookiePolicy } from 'pages/CookiePolicy';
import { ErrorFallback } from 'pages/ErrorFallback';
import { FAQ } from 'pages/FAQ';
import { Main } from 'pages/Main';
import { NationalCategory } from 'pages/NationalCategory';
import { PrivacyPolicy } from 'pages/PrivacyPolicy';
import { TermsAndConditions } from 'pages/TermsAndConditions';

import 'assets/styles/index.scss';

function App() {
  useSetUTM();
  useSetPageQueryString();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Router>
        <Layout>
          <Switch>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>
            <Route path="/cookie-policy">
              <CookiePolicy />
            </Route>
            <Route path="/frequently-asked-questions/">
              <FAQ />
            </Route>
            <Route exact path="/">
              <Main />
            </Route>
            <Route path="/*">
              <NationalCategory />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
