import React from 'react';
import { regions } from 'consts';

import { T8y } from 'ui';

import { ReactComponent as EasyToolhire } from 'assets/images/icons/easyToolhire.svg';
import { ReactComponent as RegionIcon } from 'assets/images/icons/web.svg';
import { ReactComponent as WilkoHireLogo } from 'assets/images/icons/wilko-hire.svg';

import style from './attachmentTop.module.scss';

const AttachmentTop = () => {
  return (
    <div className={style.footerAttachmentTopWrapper}>
      <div className={style.footerAttachmentContainer}>
        <div className={style.footerAttachmentRegions}>
          <T8y variant="t1" bold smCenter color="light">
            Regions
          </T8y>
          <div className={style.regionsLinks}>
            {regions.map((el, index) => (
              <div key={index} className={style.region}>
                <RegionIcon />
                <T8y
                  key={index}
                  as="a"
                  variant="t1v1"
                  color="light"
                  href={el.url}
                  ml
                >
                  {el.name}
                </T8y>
              </div>
            ))}
          </div>
        </div>
        <div className={style.logoWrapper}>
          <WilkoHireLogo className={style.wilkoHireLogo} />
          <div className={style.poweredBy}>
            <T8y color="light" bold noWrap>
              Powered by{' '}
            </T8y>
            <div className={style.easyLogoWrapper}>
              <EasyToolhire />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachmentTop;
