import React from 'react';
import { setRegionModalState } from 'models/common';

import { T8y } from 'ui';

import style from './headerNavDropdown.module.scss';

export const HeaderNavDropdown = ({ linksList, isCategories }) => {
  return (
    <div className={style.multipleLinksMenuWrapper}>
      <div
        className={style.menuItems}
        style={{ maxHeight: 'calc(100vh - 100px)' }}
      >
        {linksList.map((el, index) =>
          isCategories ? (
            <T8y
              key={index}
              onClick={() =>
                setRegionModalState({
                  open: true,
                  regions: el.regions,
                })
              }
              pointer
              className={style.link}
            >
              <div className={style.linkWrapper}>{el.title}</div>
            </T8y>
          ) : (
            <T8y
              key={index}
              as="a"
              href={el.url}
              pointer
              className={style.link}
            >
              <div className={style.linkWrapper}>{el.title}</div>
            </T8y>
          ),
        )}
      </div>
    </div>
  );
};
