import React from 'react';

import { T8y } from 'ui';

import { ReactComponent as EasyToolhireLogo } from 'assets/images/icons/easyToolhireLogo.svg';

import style from './poweredByBanner.module.scss';

export const PoweredByBanner = () => {
  return (
    <div className={style.poweredByBanner}>
      <T8y variant="t4" className={style.poweredByText}>
        Powered by
      </T8y>
      <EasyToolhireLogo />
    </div>
  );
};
