import React, { useState } from 'react';

import { T8y } from 'ui';

import arrow from 'assets/images/icons/arrow.svg';

import style from './select.module.scss';

export const Select = ({ regions, selectedRegion, setSelectedRegion }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={style.selectWrapper}>
      <div
        className={style.select}
        onClick={() => setIsOpen(prevState => !prevState)}
      >
        <div className={style.column}>
          <span>{selectedRegion.name}</span>
        </div>
        <img src={arrow} width={16} height={10} />
      </div>
      {isOpen && (
        <ul className={style.selectOptions}>
          {regions.map((el, index) => (
            <T8y
              as="li"
              key={index}
              className={style.selectOption}
              bold={el.url === selectedRegion.url}
              onClick={() => {
                setSelectedRegion(el);
                setIsOpen(false);
              }}
            >
              {el.name}
            </T8y>
          ))}
        </ul>
      )}
    </div>
  );
};
