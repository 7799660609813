import React from 'react';
import { reviews } from 'consts';

import MainContentBlock from '../MainContentBlock';

import { Rating, T8y } from 'ui';

import style from './reviews.module.scss';

const Reviews = () => {
  return (
    <MainContentBlock title="Customer reviews">
      <div>
        {reviews.map((el, index) => (
          <div key={index} className={style.review}>
            <Rating rating={el.rating} height={25} width={23} />
            <T8y as="p" className={style.reviewText}>
              {el.review}
            </T8y>
            <T8y bold className={style.reviewAuthor}>
              {el.author}
            </T8y>
          </div>
        ))}
      </div>
    </MainContentBlock>
  );
};

export default Reviews;
