import React, { useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { regions } from 'consts';
import { useStore } from 'effector-react';
import { useOnClickOutside } from 'hooks';
import { regionModalState$, setRegionModalState } from 'models/common';

import { Select } from './Select';

import { Button, T8y } from 'ui';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';

import style from './selectRegionModal.module.scss';

export const SelectRegionModal = () => {
  const regionModalState = useStore(regionModalState$);

  const modalRef = useRef(null);

  const actualRegions = useMemo(() => regionModalState.regions || regions, [
    regionModalState.regions,
  ]);

  const [selectedRegion, setSelectedRegion] = useState(actualRegions[0]);

  useOnClickOutside(modalRef, () => {
    setRegionModalState({ open: false });
  });

  return createPortal(
    <div className={style.modalOverlay}>
      <div className={style.modal} ref={modalRef}>
        <div className={style.modalHeader}>
          <T8y fullWidth center color="light" variant="h2">
            Select the region of your project
          </T8y>
          <CloseIcon
            className={style.closeIcon}
            onClick={() => setRegionModalState({ open: false })}
          />
        </div>
        <div className={style.modalContent}>
          <T8y variant="t2v1" center>
            Select region to browse products
          </T8y>
          <Select
            regions={actualRegions}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
          />
          <div className={style.buttonsWrapper}>
            <Button
              onClick={() => setRegionModalState({ open: false })}
              inverse
              className={clsx(style.modalButton, style.modalCancelButton)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => window.open(selectedRegion.url, '_self')}
              className={clsx(style.modalButton, style.modalOkButton)}
            >
              Ok
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};
