import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { sendMsgToSlack } from 'utils/sendMsgToSlack';

import { Button, T8y } from 'ui';

import { ReactComponent as EasyBrands } from 'assets/images/icons/easyBrands.svg';
import { ReactComponent as ErrorSign } from 'assets/images/icons/errorSign.svg';

import style from './errorFallback.module.scss';

export const ErrorFallback = ({ error }) => {
  const history = useHistory();

  useEffect(() => {
    sendMsgToSlack(error);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefreshPage = useCallback(() => {
    if (window.location?.pathname.includes('/404')) {
      window.location.replace('/');
    } else {
      window.location.reload();
    }
  }, []);

  return (
    <div>
      <div className={style.errorHeaderWrapper}>
        <div className={style.logoWrapper} onClick={() => history.push('/')}>
          <EasyBrands />
        </div>
      </div>
      <div className={style.errorPageContainer}>
        <div className={style.errorLogoWrapper}>
          <ErrorSign />
        </div>
        <T8y className={style.errorTitle} center bold>
          Oops... There’s something went wrong
        </T8y>
        <T8y className={style.errorSubTitle} center>
          We’re working hard to solve the problem!
        </T8y>
        <T8y className={style.refreshText} center>
          Please refresh the page and try again.
        </T8y>
        <Button onClick={handleRefreshPage} className={style.refreshButton}>
          Refresh the page
        </Button>
        <T8y className={style.errorText}>
          <T8y variant="t2" as="a" href="mailto:office@rentuu.com" ml asLink>
            Send us an email
          </T8y>{' '}
          if any questions.
        </T8y>
      </div>
    </div>
  );
};
