import React from 'react';
import clsx from 'clsx';

import { T8y } from 'ui';

import style from './mainContentBlock.module.scss';

const MainContentBlock = ({ title, children, wrapperClassName }) => {
  return (
    <div className={clsx(style.mainContentBlockWrapper, wrapperClassName)}>
      {title && (
        <T8y as="h2" className={style.mainContentBlockTitle} bold>
          {title}
        </T8y>
      )}
      {children}
    </div>
  );
};

export default MainContentBlock;
