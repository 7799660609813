import React from 'react';
import MainContentBlock from 'pages/Main/MainContentBlock';

import { CategoryCard } from 'ui';

import style from './subCategories.module.scss';

const SubCategories = ({ categories = [] }) => {
  return (
    <div className={style.sectionWrapper}>
      <MainContentBlock wrapperClassName={style.contentBlockWrapper}>
        <div className={style.categoriesWrapper}>
          {categories.map((el, index) => (
            <div key={index} className={style.categoryItem}>
              <CategoryCard category={el} />
            </div>
          ))}
        </div>
      </MainContentBlock>
    </div>
  );
};

export default SubCategories;
