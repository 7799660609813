import React, { useState } from 'react';
import { COMPANY_PHONE_NUMBER, COMPANY_PHONE_NUMBER_CALL } from 'consts';

import { Categories } from './Categories';
import { MenuItem } from './MenuItem';
import { Regions } from './Regions';

import { T8y } from 'ui';

import style from './headerNav.module.scss';

export const HeaderNav = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  return (
    <>
      <nav className={style.headerNav}>
        <MenuItem
          title="Regions"
          isSubMenuOpen={isSubMenuOpen}
          handleSetIsSubMenuOpen={setIsSubMenuOpen}
        >
          {isSubMenuOpen && <Regions />}
        </MenuItem>
        <MenuItem
          title="Categories"
          isSubMenuOpen={isSubMenuOpen}
          handleSetIsSubMenuOpen={setIsSubMenuOpen}
        >
          {isSubMenuOpen && <Categories />}
        </MenuItem>
        <MenuItem
          href="https://www.wilko.com"
          title="Back to wilko.com"
          isSubMenuOpen={isSubMenuOpen}
          handleSetIsSubMenuOpen={setIsSubMenuOpen}
          isSingleLink
        />
      </nav>
      <T8y
        variant="t1v1"
        color="light"
        as="a"
        href={`tel:${COMPANY_PHONE_NUMBER_CALL}`}
        noWrap
        bold
        className={style.headerPhone}
      >
        {COMPANY_PHONE_NUMBER}
      </T8y>
    </>
  );
};
