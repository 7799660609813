import React from 'react';
import { categories } from 'consts';

import MainContentBlock from '../MainContentBlock';

import { CategoryCard } from 'ui';

import style from './hireByCategory.module.scss';

const HireByCategory = () => {
  return (
    <MainContentBlock
      title="Browse tools and equipment"
      wrapperClassName={style.contentBlockWrapper}
    >
      <div className={style.categoriesWrapper}>
        {categories.map((el, index) => (
          <div key={index} className={style.categoryItem}>
            <CategoryCard category={el} />
          </div>
        ))}
      </div>
    </MainContentBlock>
  );
};

export default HireByCategory;
