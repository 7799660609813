import { getBrowser, getIsMobile, isBot } from './getUserBrowserInfo';

export const sendMsgToSlack = async error => {
  if (process.env.NODE_ENV === 'development' || isBot) {
    return;
  }

  const webhookUrl =
    'https://hooks.slack.com/services/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';

  const errorText = error ? error.toString() : '';

  const url = window.location.href;

  const browser = getBrowser();
  const isMobile = getIsMobile();

  const data = {
    text: `Url: ${url} \n ${errorText} \n Browser: ${browser.name} ${browser.version} \n Mobile: ${isMobile} \n User agent: ${navigator.userAgent}`,
  };

  await fetch(webhookUrl, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};
