import React from 'react';

import { BreadCrumbs } from './BreadCrumbs';
import { questions } from './questions';

import { T8y } from 'ui';

import style from './faq.module.scss';

export const FAQ = () => {
  return (
    <>
      <BreadCrumbs />
      <div className={style.faq}>
        <T8y as="h1" className={style.title} xsCenter>
          Frequently asked questions
        </T8y>
        {questions.map((el, index) => (
          <div key={index}>
            <T8y
              as="h2"
              variant="t1v3"
              xsVariant="t1"
              center
              className={style.questionsSectionTitle}
            >
              {el.title}
            </T8y>
            {el.questions.map((item, index) => (
              <div key={index} className={style.questionWrapper}>
                <T8y as="h3" variant="t1" bold className={style.question}>
                  {item.question}
                </T8y>
                <T8y as="p" variant="t1" xsVariant="t1v1">
                  {item.answer}
                </T8y>
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
