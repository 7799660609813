import React from 'react';
import { setRegionModalState } from 'models/common';

import LazyLoadingComponent from 'features/LazyLoadingComponent';

import { T8y } from 'ui';

import style from './categoryCard.module.scss';

export const CategoryCard = ({ category }) => {
  return (
    <T8y
      onClick={() =>
        setRegionModalState({ open: true, regions: category.regions })
      }
      className={style.contentCardWrapper}
    >
      <LazyLoadingComponent
        height={174}
        once
        className={style.contentCardImageWrapper}
      >
        <img
          src={category.image}
          alt="category_image"
          className={style.contentCardImage}
        />
      </LazyLoadingComponent>

      <T8y
        as="span"
        variant="t1"
        xsVariant="t3"
        className={style.contentCardTitle}
      >
        {category.name}
      </T8y>
    </T8y>
  );
};
