import React, { useMemo } from 'react';
import { categories } from 'consts';

import { HeaderNavDropdown } from '../HeaderNavDropdown';

export const Categories = () => {
  const categoriesLinks = useMemo(
    () =>
      categories.map(el => ({
        url: el.url,
        title: el.name,
        regions: el.regions,
      })),
    [],
  );

  return <HeaderNavDropdown linksList={categoriesLinks} isCategories />;
};
