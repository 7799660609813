import React from 'react';
import { Link } from 'react-router-dom';

import { T8y } from 'ui';

import style from './breadCrumbs.module.scss';

export const BreadCrumbs = () => {
  return (
    <div className={style.breadCrumbs}>
      <T8y as={Link} to="/" variant="t1" className={style.breadCrumbsLink}>
        Home
      </T8y>
      <T8y variant="t1" color="silver" ml mr>
        /
      </T8y>
      <T8y variant="t1" color="silver">
        Privacy Notice
      </T8y>
    </div>
  );
};
