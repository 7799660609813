export const questions = [
  {
    title: 'Delivery & Collection',
    questions: [
      {
        question: 'What areas do you cover?',
        answer:
          'We have depots all over the UK - simply enter your postcode at checkout to see your closest one. You can also generate delivery costs at this point if you’d like your items to be delivered or collected.',
      },
      {
        question: 'Do you have a distribution centre close to me?',
        answer:
          'Probably! We currently have 25 distribution centres across the UK: Alnwick, Ayr, Blackburn, Blyth, Bolton, Chorley, Cumbria, Edinburgh, Falkirk, Govan, Kirkcaldy, Liverpool, London, Manchester, Middlesborough, Motherwell, Newcastle, Newton le Willows, Perth, Preston, South Shields, Sunderland, Wigan, Wirral, and Wythenshawe. Generally speaking, the closer you are to a distribution centre, the cheaper your transport charges!',
      },
      {
        question: 'Am I able to collect in-person?',
        answer:
          'Of course, you’re welcome to collect your items in-person and avoid transport charges. However, for heavy or bulky items, we’d recommend booking our seamless delivery and collection service. For more information on the location of our distribution centres, visit our website.',
      },
      {
        question: 'Do you offer a transport service?',
        answer:
          'Yes – we can deliver to most parts of North East and North West England, London & the surrounding areas, and Scotland.',
      },
      {
        question: 'Delivery and Collection Services?',
        answer:
          'We offer delivery and collection for most items, subject to availability. Contact us for details and pricing in your area.',
      },
      {
        question: 'Self-Pickup and Equipment Return?',
        answer:
          'Opt for self-pickup at our depots. Return equipment in its original condition to the agreed location, or arrange for collection if possible.',
      },
      {
        question: 'Scheduling Collection After Use?',
        answer:
          'Easily schedule equipment collection via our website or app, or contact our team. Please provide at least a 24-hour notice.',
      },
      {
        question: 'Late Collection Fees?',
        answer:
          'To avoid late collection fees, ensure equipment is ready for pickup at the agreed time. Fees apply per our policy on delayed returns.',
      },
      {
        question: 'Do you provide delivery and pickup services?',
        answer:
          'We offer delivery and pickup for an additional fee. Please indicate your preference when booking.',
      },
    ],
  },
  {
    title: 'Ordering & Payment',
    questions: [
      {
        question: 'How can I pay?',
        answer:
          'You can pay online using all major debit & credit card providers.',
      },
      {
        question: 'When do I pay?',
        answer: 'Payment is required in full upon order to confirm your hire.',
      },
      {
        question: 'When I Press ‘Pay Now’, is my card immediately charged?',
        answer:
          'No. Your order will initially go through a pre-authorisation system, which enables our sales team to double check that we can fulfil your order before accepting it. You will be notified of the acceptance or decline of your order via email, usually on the same working day. Don’t worry, you won’t be charged twice!',
      },
      {
        question: 'What is pre-authorisation?',
        answer:
          'A pre-authorisation is a temporary hold, sometimes called a ‘pending transaction’, on your selected debit or credit card. You don’t need to do anything, and your card will be charged once our sales team confirms the order.',
      },
      {
        question:
          'What happens to the pre-authorisation charge if my order is declined?',
        answer:
          'If we must decline your order, we simply cancel the pre-authorisation charge. Please be aware that your bank may not release the funds for up to five days, which is something we unfortunately can’t control. You will need to call your bank if you require the funds returned faster.',
      },
      {
        question: 'What if I want to make changes to my order?',
        answer:
          'You can email us at any time to make changes to your order, or if you have any questions.',
      },
      {
        question: 'How can I modify or cancel the order?',
        answer:
          'You can modify your booking details up to 24 hours prior to your rental start date. Please note, changes may be subject to a nominal administrative fee to cover processing costs. For a full refund, cancellations must also be made more than 24 hours in advance of your scheduled start date.',
      },
      {
        question: 'Payment Methods and Deposits',
        answer:
          'We accept major credit/debit cards and select online payments. A refundable deposit may be required for some items.',
      },
      {
        question: 'Is a deposit necessary for tool rental?',
        answer:
          "Yes, a refundable deposit is required. The amount varies depending on the tool and is refunded upon the tool's return in its original condition.",
      },
      {
        question: 'What identification is required to rent a tool?',
        answer:
          'A valid government-issued photo ID and a secondary form of identification, like a credit card or utility bill with your address, are required.',
      },
      {
        question: 'What is your policy on cancellations?',
        answer:
          'Reservations can be canceled without a charge up to 24 hours before the scheduled pickup time. Late cancellations may be subject to a fee.',
      },
    ],
  },
  {
    title: 'Prices',
    questions: [
      {
        question: 'How can I get the best prices?',
        answer:
          'All the best prices for quality tool and equipment hire can be found by booking through our website.',
      },
      {
        question: 'How does your equipment pricing work?',
        answer:
          'You pay a flat rental fee for hires up to a week, then an additional charge thereafter. All pricing is included on our website.',
      },
      {
        question: 'And what about your transport pricing?',
        answer:
          'The price of our transport service is based upon your postcode location in Scotland – the further your location from our distribution centres, the more expensive the transport becomes, and vice-versa. It also assumes standard delivery and collection, weekdays 0730hrs – 1700hrs.',
      },
      {
        question: 'Is there a refundable deposit I need to pay?',
        answer:
          'Yes, the value of which is dependent on your hire items. Presuming you return all items in the same condition and quantities, this will be returned to you in full. Any damages or missing items will be deducted from the deposit appropriately.',
      },
      {
        question: 'Do you price match?',
        answer:
          'We don’t; our prices are some of the best in the industry both in terms of the quality and variety of the products we have available, as well as the high levels of customer service.',
      },
      {
        question: 'Determining Rental Prices?',
        answer:
          'Prices vary by equipment type and rental duration. Visit our product pages or contact us for specific details.',
      },
      {
        question: 'Security Deposit Information?',
        answer:
          "Deposits are based on the equipment’s value and rental duration. They're fully refundable upon timely and undamaged return.",
      },
      {
        question: 'Discounts and Offers?',
        answer:
          'Stay updated on special offers and discounts by subscribing to our newsletter or visiting our offers page.',
      },
    ],
  },
  {
    title: 'Booking and Rental Process',
    questions: [
      {
        question: 'Do you have a minimum order value?',
        answer: 'Nope. You can use wilko tool hire for any jobs, big or small!',
      },
      {
        question: 'How soon should I make my reservation?',
        answer:
          'You should aim to reserve your items as soon as possible to avoid disappointment, as our items are usually in high demand.',
      },
      {
        question: 'I need to hire some equipment today, can you help?',
        answer:
          'We usually work on a next-day-onwards basis, though your best bet is to get in touch with one of our friendly advisors via email, phone, or live chat; if there is anything we can do to help, we will!',
      },
      {
        question: 'What equipment can I hire?',
        answer:
          'What do you need? We offer everything from cherry pickers to cordless drills, mini diggers to micro diggers, generators to gardening equipment and concrete mixers to concrete finishing tools and so much more! Jump over to our hire section to explore the range.',
      },
      {
        question: 'How much equipment do you have in stock?',
        answer:
          'With 25 national depots and thousands of products in stock, we’re confident that we can serve our customers efficiently. However, we still recommend booking as soon as possible to avoid disappointment.',
      },
      {
        question: 'What is my responsibility for equipment return?',
        answer:
          'All equipment should be returned in the same condition and quantities that it was received, or you will incur charges.',
      },
      {
        question: 'How to Book equipment?',
        answer:
          'Easily book through our website, app, or by phone. Choose your tool, select rental dates, and confirm your reservation.',
      },
      {
        question: 'Required Documentation?',
        answer:
          'Valid ID and proof of address are needed. Some items may require additional documents, such as insurance proof.',
      },
      {
        question: 'Availability Inquiry?',
        answer:
          'Check real-time availability online or contact us directly. We continuously update our inventory to reflect current stock levels.',
      },
      {
        question: 'Account Creation for Booking?',
        answer:
          'Create an account for a faster booking process, order tracking, and easy access to rental history.',
      },
      {
        question: 'Rental Duration Flexibility?',
        answer:
          'Choose from daily, weekly, or monthly rental options. Need a custom duration? Contact us to tailor your rental period.',
      },
      {
        question: 'Late Return Policy?',
        answer:
          'Additional charges apply for late returns as outlined in our rental agreement. Please plan accordingly to avoid extra fees.',
      },
      {
        question: 'Insurance Coverage Queries?',
        answer:
          'Every order has a mandatory damage waiver in place for damage & theft protection.',
      },
      {
        question: 'Extending Your Rental?',
        answer:
          "Need more time? To extend your rental period, please reach out to us at least 24 hours before your current rental agreement is due to end. Extensions are granted based on the availability of the equipment. It's important to note that requests made less than 24 hours before the end of your rental may lead to a collection fee. This fee is applied if our courier service has already been dispatched for the collection of your equipment.",
      },
      {
        question: 'Using the Equipment Safely?',
        answer:
          "Adhere to the user manual and wear safety gear. For safety questions or concerns, don't hesitate to reach out to us.",
      },
      {
        question: 'Malfunction and Technical Support?',
        answer:
          'Stop use immediately if equipment malfunctions. Contact us for immediate support, replacement, or refund arrangements.',
      },
      {
        question: 'Feedback and Improvements?',
        answer:
          'Your feedback is invaluable. Share your rental experience with us through the link in your confirmation email or directly on our website.',
      },
      {
        question: 'Equipment Training or Demo?',
        answer:
          'First time or need a refresher? Request equipment training or a demo at pickup or delivery (availability may vary).',
      },
      {
        question: 'Reporting Equipment Damage?',
        answer:
          'If you receive damaged or non-functioning equipment, report it immediately to our team for a swift resolution.',
      },
      {
        question: 'How can I make a reservation for a tool?',
        answer:
          'Reservations can be made on our website or by contacting our customer service team. Select the tool, choose your rental period, and proceed with the checkout process.',
      },
      {
        question: 'What should I do if the tool malfunctions?',
        answer:
          "Immediately report any tool malfunctions. We will provide a replacement or offer a refund if a replacement isn't available.",
      },
      {
        question:
          'Are there any rental restrictions or certifications required for certain tools?',
        answer:
          "Some tools may require proof of certification or qualifications. Check the tool's description on our website or contact us for details.",
      },
    ],
  },
];
