import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HeroBanner from 'commonComponents/HeroBanner';
import { PoweredByBanner } from 'commonComponents/PoweredByBanner';
import Regions from 'commonComponents/Regions';
import { nationalCategories } from 'consts';

import { EnquiryLead } from 'features/EnquiryLead';

import Description from './Description';
import SubCategories from './SubCategories';

export const NationalCategory = () => {
  const history = useHistory();

  const [category, setCategory] = useState(null);

  useLayoutEffect(() => {
    const formattedPathname = window.location.pathname
      .replace('/', '')
      .replace('/', '');

    const category = nationalCategories.find(
      el => el.slug === formattedPathname,
    );

    if (!category) {
      history.replace('/');
    } else {
      setCategory(category);
    }
  }, [history]);

  const formattedSubCategories = useMemo(() => {
    if (!category) {
      return [];
    }
    return category.subCategories.map(el => ({
      ...el,
      regions: category.regions,
    }));
  }, [category]);

  if (!category) {
    return null;
  }

  return (
    <div>
      <PoweredByBanner />
      <HeroBanner category={category} />
      <Regions categorySlug={category.slug} />
      <SubCategories categories={formattedSubCategories} />
      <Description category={category} />
      <EnquiryLead source="Contact form (national category)" />
    </div>
  );
};
