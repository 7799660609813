import React from 'react';
import clsx from 'clsx';
import { regions } from 'consts';

import { T8y } from 'ui';

import { ReactComponent as Arrow } from 'assets/images/icons/dropDownArrow.svg';

import style from '../mobileHeader.module.scss';

const SubMenuRegions = ({ id, openId, setOpenId }) => {
  const isOpen = openId === id;

  return (
    <div className={clsx(style.subMenu, isOpen && style.open)}>
      <div
        className={style.subMenuTitle}
        onClick={() => setOpenId(isOpen ? 0 : id)}
      >
        <T8y variant="t1">Regions</T8y>
        <Arrow />
      </div>
      {isOpen && (
        <div>
          {regions.map((el, index) => (
            <T8y
              key={index}
              as="a"
              href={el.url}
              variant="t1v1"
              className={style.subMenuItem}
            >
              {el.name}
            </T8y>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubMenuRegions;
