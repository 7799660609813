import React from 'react';
import clsx from 'clsx';
import { howItWorks } from 'consts';

import { setRegionModalState } from '../../../models/common';
import MainContentBlock from '../MainContentBlock';

import { Button, T8y } from 'ui';

import style from './howItWorksSection.module.scss';

const HowItWorksSection = () => {
  return (
    <MainContentBlock title="How it works">
      <div className={style.benefits}>
        {howItWorks.map((el, i) => (
          <div key={i} className={style.benefitWrapper}>
            <div
              className={clsx(
                style.benefitIcon,
                i === 0 ? style.benefitFirstIcon : undefined,
              )}
            >
              <img src={el.icon} alt={'benefit' + i + 1} />
            </div>
            <div>
              <T8y as="p" variant="t1" xsVariant="t1v1" bold>
                {el.title}
              </T8y>
              <T8y xsVariant="t2v1">{el.text}</T8y>
            </div>
          </div>
        ))}
      </div>
      <Button
        className={style.selectRegionBenefitBtn}
        onClick={() => setRegionModalState({ open: true })}
      >
        Select your region
      </Button>
    </MainContentBlock>
  );
};

export default HowItWorksSection;
