import React, { useCallback, useState } from 'react';
import { MOBILE_SCREEN, warehouses } from 'consts';
import { useMatchMedia } from 'hooks';

import GoogleMapsWrapper from 'features/GoogleMapsWrapper';

import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';

import MainContentBlock from '../MainContentBlock';

import { T8y } from 'ui';

import markerDefault from 'assets/images/icons/newMapMarker.svg';
import markerActive from 'assets/images/icons/newMapMarkerActive.svg';

import style from './mapSection.module.scss';

const MapSection = () => {
  const [mapCenter, setMapCenter] = useState();
  const [viewDepot, setViewDepot] = useState(0);

  const isMobile = useMatchMedia(`(max-width: ${MOBILE_SCREEN}px)`);

  const onLoadMap = useCallback(() => {
    setMapCenter({
      lat: 55,
      lng: -3.30185,
    });
  }, []);

  return (
    <MainContentBlock
      title="We are expanding throughout the UK"
      wrapperClassName={style.mapWrapper}
    >
      <GoogleMapsWrapper>
        <GoogleMap
          id="circle-example"
          center={mapCenter}
          onLoad={onLoadMap}
          zoom={isMobile ? 6 : 5}
          onClick={() => setViewDepot(null)}
          options={{
            panControl: false,
            scaleControl: false,
            overviewMapControl: false,
          }}
          mapContainerClassName={style.map}
        >
          {warehouses.map((el, index) => (
            <Marker
              key={index}
              position={{ lat: el.lat, lng: el.lng }}
              icon={viewDepot === index ? markerActive : markerDefault}
              onClick={() => setViewDepot(index)}
              title={el.name}
            >
              {viewDepot === index && (
                <InfoWindow onCloseClick={() => setViewDepot(null)}>
                  <div>
                    <T8y variant="t1" bold mb>
                      {el.name}
                    </T8y>
                    <T8y variant="t1v1">{el.address}</T8y>
                    <T8y
                      as="a"
                      href={el.googleMapLink}
                      variant="t1v1"
                      target="_blank"
                      rel="noopener noreferrer"
                      asLink
                      className={style.googleMapsLink}
                    >
                      View on Google Maps
                    </T8y>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      </GoogleMapsWrapper>
    </MainContentBlock>
  );
};

export default MapSection;
