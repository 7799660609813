import React from 'react';
import clsx from 'clsx';

import style from './typography.module.scss';

// *docs
// ** variants
// *** h1 , h2 , h3 , mainText, smallText
// ** color
// *** primary , secondary, light
// ** styles -> custom styles (rest for this)
export const T8y = React.forwardRef(
  (
    {
      children,
      variant = 't2',
      lgVariant = variant,
      mdVariant = lgVariant,
      smVariant = mdVariant,
      xsVariant = smVariant,
      color = 'secondary',
      inline,
      bold,
      boldLight,
      center,
      end,
      regular,
      className,
      asLink,
      pointer,
      uppercase,
      capitalize,
      firstLetterUp,
      hidden,
      noWrap,
      italic,
      ml,
      ml2,
      ml3,
      mr,
      mr2,
      mr3,
      mb,
      mb2,
      mb3,
      mb4,
      mb5,
      mt,
      mt2,
      mt3,
      mt4,
      mt5,
      mt6,
      mt7,
      mt8,
      mt9,

      xsCenter,
      smCenter,
      mdCenter,
      xsSmall,

      fullWidth,
      xsFullWidth,
      smFullWidth,
      mdFullWidth,
      lgFullWidth,
      boldFont,
      linkOpposite,
      as: Component = 'div',
      ...rest
    },
    ref,
  ) => (
    <Component
      className={clsx(
        [
          style[`T8y-${variant}`],
          style[`T8y-lg-${lgVariant}`],
          style[`T8y-md-${mdVariant}`],
          style[`T8y-sm-${smVariant}`],
          style[`T8y-xs-${xsVariant}`],
          style[`T8yColor-${color}`],
          className,
        ],
        {
          [style.inline]: inline,
          [style.boldLight]: boldLight,
          [style.bold]: bold,
          [style.end]: end,
          [style.linkOpposite]: linkOpposite,
          [style.boldFont]: boldFont,
          [style.center]: center,
          [style.regular]: regular,
          [style.link]: asLink,
          [style.pointer]: pointer,
          [style.uppercase]: uppercase,
          [style.capitalize]: capitalize,
          [style.firstLetterUp]: firstLetterUp,
          [style.hidden]: hidden,
          [style.noWrap]: noWrap,
          [style.italic]: italic,
          [style.ml]: ml,
          [style.ml2]: ml2,
          [style.ml3]: ml3,
          [style.mr]: mr,
          [style.mr2]: mr2,
          [style.mr3]: mr3,
          [style.mb]: mb,
          [style.mb2]: mb2,
          [style.mb3]: mb3,
          [style.mb4]: mb4,
          [style.mb5]: mb5,
          [style.mt]: mt,
          [style.mt2]: mt2,
          [style.mt3]: mt3,
          [style.mt4]: mt4,
          [style.mt5]: mt5,
          [style.mt6]: mt6,
          [style.mt7]: mt7,
          [style.mt8]: mt8,
          [style.mt9]: mt9,
          [style.xsCenter]: xsCenter,
          [style.smCenter]: smCenter,
          [style.mdCenter]: mdCenter,
          [style.xsSmall]: xsSmall,

          [style.fullWidth]: fullWidth,
          [style.xsFullWidth]: xsFullWidth,
          [style.smFullWidth]: smFullWidth,
          [style.mdFullWidth]: mdFullWidth,
          [style.lgFullWidth]: lgFullWidth,
        },
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </Component>
  ),
);
