import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { COMPANY_PHONE_NUMBER_CALL, SMALL_SCREEN } from 'consts';
import { useMatchMedia } from 'hooks';

import { HeaderNav } from './HeaderNav';
import MobileHeaderNav from './MobileHeaderNav';

import { ReactComponent as CallIcon } from 'assets/images/icons/phoneWhite.svg';
import { ReactComponent as Logo } from 'assets/images/icons/wilko-hire.svg';

import style from './header.module.scss';

export const Header = () => {
  const isSmallScreen = useMatchMedia(`(max-width: ${SMALL_SCREEN}px)`);

  const [isBannerDisplayed, setIsBannerDisplayed] = useState(
    document.getElementById('promo-header-1'),
  );

  useLayoutEffect(() => {
    const bannerEl = document.getElementById('promo-header-1');

    if (!bannerEl) {
      return;
    }

    const setIsBack = () => {
      setIsBannerDisplayed(false);
    };

    bannerEl.addEventListener('click', setIsBack);

    return () => {
      if (bannerEl) {
        bannerEl.removeEventListener('click', setIsBack);
      }
    };
  }, []);

  return (
    <header
      className={clsx(
        style.header,
        isBannerDisplayed ? style.headerUnderBanner : undefined,
      )}
    >
      <div className={style.headerContainer}>
        {isSmallScreen ? (
          <>
            <MobileHeaderNav />
            <Link to="/">
              <Logo className={style.headerLogo} />
            </Link>
            <a href={`tel:${COMPANY_PHONE_NUMBER_CALL}`}>
              <CallIcon />
            </a>
          </>
        ) : (
          <>
            <Link to="/">
              <Logo className={style.headerLogo} />
            </Link>
            <HeaderNav />
          </>
        )}
      </div>
    </header>
  );
};
